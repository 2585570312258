

const branding = [
  /*
  // Grab Labs
  {
    title: 'Grab Labs',
    desc: '',
    subs: [
      {
        label: 'Logo & Collateral',
        imgs: [
          {
            path: 'img/branding/Grab-Labs-1.jpg',
            style: {
              width: 1024,
              height: 768,
            },
          },
        ],
      }
    ],
  },
  */

  // Anderson Media Group
  {
    title: 'Anderson Media Group',
    desc: 'Art direction of branding, collateral, and website design for Anderson Media Group, a media company specializing in distributing, acquiring, and producing film and television content. The final logo subtly references an old film countdown.',
    subs: [
      {
        label: 'Logo & Collateral',
        imgs: [
          {
            path: 'img/branding/branding-011.jpg',
            style: {
              width: 445,
              height: 320,
            },
          },
          {
            path: 'img/branding/branding-012.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-013.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-014.jpg',
            style: {
              width: 445,
              height: 320
            },
          }
        ],
      },
    ],
  },

  // Colonia
  {
    title: 'Colonia',
    desc: 'Art direction of branding and design of supplemental illustrations for Colonia, an Argentinian leather bike saddle brand. Final logo design by Sara Pastrana.',
    subs: [
      {
        label: 'Logo & Illustration',
        imgs: [
          {
            path: 'img/branding/branding-015.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-016.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ],
      },
      {
        label: 'Additional Logo Explorations',
        imgs: [
          {
            path: 'img/branding/branding-017.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-018.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ],
      },
    ],
  },

  // The Sunset Strip
  {
    title: 'The Sunset Strip',
    desc: 'Art direction of rebrand for The Sunset Strip. The goal of the rebrand was to revitalize the Strip and make it a hip, current destination while embracing its heritage. The final logo features custom type with hard angles inspired by rock typography enclosed in a shape that references a destination marker on a map.',
    subs: [
      {
        label: 'Logo',
        imgs: [
          {
            path: 'img/branding/branding-019.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-020.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ]
      },
      {
        label: 'Additional Logo Explorations',
        imgs: [
          {
            path: 'img/branding/branding-021.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-022.jpg',
            style: {
              width: 445,
              height: 320
            },
          }
        ],
      },
    ],
  },
  /*
  // Ourspace
  {
    title: 'Ourspace',
    desc: 'Branding for Ourspace, a social network for the African American community.',
    subs: [
      {
        label: 'Logo',
        imgs: [
          {
            path: 'img/branding/branding-001.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-002.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ]
      },
      {
        label: 'Additional Logo Explorations',
        imgs: [
          {
            path: 'img/branding/branding-003.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-004.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ],
      },
    ],
  },
  */
  
  // SSMF
  {
    title: 'The Sunset Strip Music Festival',
    desc: 'Art direction of branding, collateral, and website design for the first annual Sunset Strip Music Festival. The final logo and collateral featuring black and white rock photography are a nod to classic rock.',
    subs: [
      {
        label: 'Logo & Collateral',
        imgs: [
          {
            path: 'img/branding/branding-005.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-006.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-007.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-008.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-009.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-010.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ],
      },
    ],
  },
  
  // Macdonald Boyd & Associates
  {
    title: 'MacDonald Boyd & Associates',
    desc: '',
    subs: [
      {
        label: 'Logo',
        imgs: [
          {
            path: 'img/branding/branding-027.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-028.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ],
      },
    ],
  },
  /*
  // Live 25
  {
    title: 'Live 25',
    desc: '',
    subs: [
      {
        label: 'Logo & Stationery',
        imgs: [
          {
            path: 'img/branding/branding-030.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-031.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ],
      },
    ],
  },
  */
  /*
  // Additional Work
  {
    title: 'Additional Work',
    desc: '',
    subs: [
      {
        label: '',
        imgs: [
          {
            path: 'img/branding/branding-030.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-023.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-024.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-025.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/branding/branding-029.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ],
      },
    ],
  },
  */
];


// END BRANDING


// PACKAGING

const packaging = [
  // Crusoe Organic Rum
  {
    title: 'Crusoe Organic Rum',
    desc: '',
    subs: [
      {
        label: '',
        imgs: [
          {
            path: 'img/packaging/packaging-003.jpg',
            style: {
              width: 900,
              height: 530
            },
          },
        ],
      },
    ],
  },

  // Le Pain Quotidien
  {
    title: 'Le Pain Quotidien',
    desc: 'Sustainable packaging concepts for Le Pain Quotidien, a Belgian restaurant and bakery. Inspired by Le Pain Quotidien\'s focus on "the communal table", the packaging is modeled after tableware. The reusable shopping bag doubles as a bread basket; the reusable pastry bag functions as a napkin.',
    subs: [
      {
        label: 'Packaging Prototypes',
        imgs: [
          {
            path: 'img/packaging/packaging-005.jpg',
            style: {
              width: 900,
              height: 445
            },
          },
          {
            path: 'img/packaging/packaging-006.jpg',
            style: {
              width: 900,
              height: 225
            },
          },
          {
            path: 'img/packaging/packaging-007.jpg',
            style: {
              width: 900,
              height: 225
            },
          },
        ],
      },
    ],
  },

  // Minute Scrapbooker
  {
    title: 'Minute Scrapbooker',
    desc: 'Packaging for scrapbooking kit. The project included naming of the product line and design of packaging and display materials. The goal was to engage on-the-go moms by providing a quick and easy solution to their scrapbooking needs. "Preserve precious memories. Save precious time."',
    subs: [
      {
        label: 'Product Packaging',
        imgs: [
          {
            path: 'img/packaging/packaging-001.jpg',
            style: {
              width: 900,
              height: 460
            },
          },
        ],
      },
      {
        label: 'Display Signage',
        imgs: [
          {
            path: 'img/packaging/packaging-002.jpg',
            style: {
              width: 900,
              height: 400
            },
          },
        ],
      },
    ],
  },

  // Witherspoon Sweet Tea
  {
    title: 'Witherspoon Sweet Tea',
    desc: 'Collectible glassware as bottle for sweet tea.',
    subs: [
      {
        label: 'Packaging Sketches',
        imgs: [
          {
            path: 'img/packaging/packaging-004.jpg',
            style: {
              width: 900,
              height: 600
            },
          },
        ],
      },
    ],
  },
];

// END PACKAGING


// ETC

const etc = [
  // Envelope Shopping Bag
  {
    title: 'Envelope Shopping Bag',
    desc: '',
    subs: [
      {
        label: '',
        imgs: [
          {
            path: 'img/etc/etc-001.jpg',
            style: {
              width: 900,
              height: 400,
            },
          },
        ],
      },
    ],
  },

  // Schott's Miscellany
  {
    title: 'Schott\'s Miscellany',
    desc: 'Typographic redesign of Schott\'s Miscellany. Whereas the original is set in a neutral style, the redesign seeks to incorporate styles and connotations relative to content.',
    subs: [
      {
        label: 'Spreads',
        imgs: [
          {
            path: 'img/etc/etc-002.jpg',
            style: {
              width: 900,
              height: 1010,
            },
          },
        ],
      },
    ],
  },
  /*

  // Bad Apple
  {
    title: 'Bad Apple',
    desc: 'Custom typeface. Work in progress.',
    subs: [
      {
        label: 'Packaging Prototypes',
        imgs: [
          {
            path: 'img/etc/etc-003.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
          {
            path: 'img/etc/etc-004.jpg',
            style: {
              width: 445,
              height: 320
            },
          },
        ],
      },
    ],
  },
  */

  // UPS
  {
    title: 'UPS',
    desc: 'Set of posters for UPS that combine one word + one image to articulate a defining charateristic of the brand.',
    subs: [
      {
        label: 'Posters',
        imgs: [
          {
            path: 'img/etc/etc-005.png',
            style: {
              width: 900,
              height: 594
            },
          },
        ],
      },
    ],
  },
];

// END ETC


// WEB DESIGN

const webDesign = [
  // Thomas O'Brien Blog
  {
    title: 'Thomas O\'Brien Blog',
    desc: '',
    subs: [
      {
        label: '',
        type: 'gallery',
        imgs: [
          {
            title: 'Notes from Thomas O\'Brien',
            url: 'http://aerostudios.com/fromthedeskof/',
            path: 'img/web/web-005.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
          {
            title: 'Notes from Thomas O\'Brien',
            url: 'http://aerostudios.com/fromthedeskof/',
            path: 'img/web/web-006.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
        ],
      },
    ],
  },

  // The Sunset Strip
  {
    title: 'The Sunset Strip',
    desc: '',
    subs: [
      {
        label: '',
        type: 'gallery',
        imgs: [
          {
            title: 'The Sunset Strip',
            url: 'http://www.thesunsetstrip.com/',
            path: 'img/web/web-007.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
          {
            title: 'The Sunset Strip',
            url: 'http://www.thesunsetstrip.com/',
            path: 'img/web/web-008.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
        ],
      },
    ],
  },

  // Pure Bar
  {
    title: 'Pure Bar',
    desc: '',
    subs: [
      {
        label: '',
        type: 'gallery',
        imgs: [
          {
            title: 'Pure Bar &mdash; Natural, Healthy, Organic, Gluten-Free Snack Bar',
            url: 'http://www.thepurebar.com/',
            path: 'img/web/web-003.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
          {
            title: 'Pure Bar &mdash; Natural, Healthy, Organic, Gluten-Free Snack Bar',
            url: 'http://www.thepurebar.com/',
            path: 'img/web/web-004.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
        ],
      },
    ],
  },

  // Matter
  {
    title: 'Matter',
    desc: '',
    subs: [
      {
        label: '',
        type: 'gallery',
        imgs: [
          {
            title: 'Matter',
            url: 'http://www.matter-edelman.com/',
            path: 'img/web/web-001.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
          {
            title: 'Matter',
            url: 'http://www.matter-edelman.com/',
            path: 'img/web/web-002.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
        ],
      },
    ],
  },

  // Additional Work
  {
    title: 'Additional Work',
    desc: '',
    subs: [
      {
        label: '',
        imgs: [
          {
            title: 'Anderson Media Group',
            url: 'http://www.amedia.net/',
            path: 'img/web/web-013.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
          {
            title: 'MacDonald Boyd & Associates',
            url: 'http://www.macboyd.com/',
            path: 'img/web/web-014.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
          {
            title: 'ALLISON REIMOLD',
            url: 'http://www.allisonreimold.com/',
            path: 'img/web/web-011.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
          {
            title: 'POPULATION 1280 FILMS',
            url: 'http://www.population1280films.com/',
            path: 'img/web/web-010.jpg',
            style: {
              width: 891,
              height: 560,
            },
          },
        ],
      },
    ],
  },
];
// END WEB DESIGN

const twitchExtensions = [
  {
    title: 'Mod Shoutouts!',
    desc: 'A simple panel extension for Twitch streamers to display their moderators on their channel.',
    links: [{
      label: 'View Extension',
      url: 'https://dashboard.twitch.tv/extensions/kwf0dsysje4fqnnh3w3g5eruyysa40',
    }],
    subs: [
      {
        // type: 'gallery',
        imgs:  [
          {
            path: 'img/twitch/Mod-Shoutouts-Screenshot-1-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Mod-Shoutouts-Screenshot-2-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Mod-Shoutouts-Screenshot-3-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Mod-Shoutouts-Screenshot-4-1024x768.png',
            style: { width: 1024, height: 768, },
          },
        ],
      },
    ],
  },
  {
    title: 'Ctrl+ Stream Kit',
    desc: 'A customizable set of tools for Twitch creators to connect, engage, and build their communities.',
    links: [{
      label: 'View Extension',
      url: 'https://dashboard.twitch.tv/extensions/skt182bso1i1ah7dnmkwqkdk26dak4',
    }],
    subs: [
      {
        // type: 'gallery',
        imgs:  [
          {
            path: 'img/twitch/Ctrl+-Screenshot-1-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Ctrl+-Screenshot-2-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Ctrl+-Screenshot-3-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Ctrl+-Screenshot-4-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Ctrl+-Screenshot-5-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Ctrl+-Screenshot-6-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Ctrl+-Screenshot-7-1024x768.png',
            style: { width: 1024, height: 768, },
          },
        ],
      },
    ],
  },
  
  {
    title: `TNF Pick'em Live`,
    desc: 'An extension that added a layer of interactivity and competition to Thursday Night Football on Twitch. Streamers competed by making game predictions along with help from viewers. ',
    links: [
      {
        label: 'View Extension',
        url: 'https://dashboard.twitch.tv/extensions/beppk6jwody2s05grs2psadayasnqx',
      },
    ],
    subs: [
      {
        // type: 'gallery',
        imgs:  [
          {
            path: 'img/twitch/TNF-Pickem-Live-Screenshot-2-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/TNF-Pickem-Live-Screenshot-3-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/TNF-Pickem-Live-Screenshot-4-1024x768.png',
            style: { width: 1024, height: 768, },
          },
        ],
      },
    ],
  },  {
    title: 'Wingstop Order Generator',
    desc: '',
    links: [
      {
        label: 'View Extension',
        url: 'https://dashboard.twitch.tv/extensions/hepfu6jqw404d1seqcef8whpes2dnb-1.0.0',
      },
    ],
    subs: [
      {
        // type: 'gallery',
        imgs:  [
          {
            path: 'img/twitch/Wingstop-Order-Generator-Screenshot-1-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Wingstop-Order-Generator-Screenshot-2-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Wingstop-Order-Generator-Screenshot-3-1024x768.png',
            style: { width: 1024, height: 768, },
          },
        ],
      },
    ],
  },
  {
    title: 'Chipotle Burrito Builder',
    desc: '',
    links: [{
      label: 'View Extension',
      url: 'https://dashboard.twitch.tv/extensions/0tyyymwm968e2lohj9fxdn2e9t32c7',
    }],
    subs: [
      {
        // type: 'gallery',
        imgs:  [
          {
            path: 'img/twitch/Chipotle-Burrito-Builder-Screenshot-1-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Chipotle-Burrito-Builder-Screenshot-2-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          /*
          {
            path: 'img/twitch/Chipotle-Burrito-Builder-Screenshot-3-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          */
          {
            path: 'img/twitch/Chipotle-Burrito-Builder-Screenshot-4-1024x768.png',
            style: { width: 1024, height: 768, },
          },
        ],
      },
    ],
  },
  {
    title: 'Charmin Deuce Destroyer',
    desc: '',
    links: [
      {
        label: 'Watch TimTheTatman Clip',
        url: 'https://clips.twitch.tv/ImportantFilthyCarabeefOptimizePrime',
      },
      {
        label: 'View Extension',
        url: 'https://dashboard.twitch.tv/extensions/0swim8fxm1boi4k0645vhu82zpo1wc',
      },
    ],
    subs: [
      {
        // type: 'gallery',
        imgs:  [
          {
            path: 'img/twitch/Charmin-Deuce-Destroyer-Screenshot-1-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Charmin-Deuce-Destroyer-Screenshot-2-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Charmin-Deuce-Destroyer-Screenshot-3-1024x768.png',
            style: { width: 1024, height: 768, },
          },
          {
            path: 'img/twitch/Charmin-Deuce-Destroyer-Screenshot-4-1024x768.png',
            style: { width: 1024, height: 768, },
          },
        ],
      },
    ],
  },
  {
    title: 'Other Extensions',
    desc: `There's lots more! Head to the Grab Labs website to see more of my work.`,
    links: [
      {
        label: 'View More on grablabs.com',
        url: 'https://grablabs.com/twitch',
      },
      // {
      //   label: 'Airheads Polling',
      //   url: 'https://dashboard.twitch.tv/extensions/ezk2mb6e3rf85lkbld9rogu83izgjv',
      // },
      // {
      //   label: 'Character Trigger by Adobe',
      //   url: 'https://dashboard.twitch.tv/extensions/tk9dvwa0jdktpkzov137bx61gp8wvh',
      // },
      // {
      //   label: 'Destiny 2 Armory Overlay',
      //   url: 'https://dashboard.twitch.tv/extensions/fozlqfmrrdtastjnfxw3pmnqto9zns',
      // },
      // {
      //   label: 'Elite Squad Trivia',
      //   url: 'https://dashboard.twitch.tv/extensions/1bkiix910ch90vgsdhranznrgvczs2',
      // },
      // {
      //   label: 'Hershey’s Trick or Treat',
      //   url: 'https://dashboard.twitch.tv/extensions/ckx4fqzyd0d1mzr58fofyl7vwmak2n'
      // },
      // {
      //   label: 'Honda Head2Head',
      //   url: 'https://dashboard.twitch.tv/extensions/uiiev4hp31lk9jl3t43w26ggp32kw1',
      // },
      // {
      //   label: 'Hype Check',
      //   url: 'https://dashboard.twitch.tv/extensions/nb1fy7du5dxxntayw5nqzdjg0fu5h2',
      // },
      // {
      //   label: 'Insomniac Beyond Wonder Points',
      //   url: 'https://dashboard.twitch.tv/extensions/adfgg6821cs62f4s566d30xonm3ziq',
      // },
      // {
      //   label: 'NWHL Live',
      //   url: 'https://dashboard.twitch.tv/extensions/mx8imve9jsliotbt7w6trgj7x9od9f',
      // },
      // {
      //   label: 'OMEN Coin Quest',
      //   url: 'https://dashboard.twitch.tv/extensions/ex80nbdqniqgxszfjvkdlyrw7zajt4',
      // },
      // {
      //   label: 'PrePre Show Click Simulator',
      //   url: 'https://dashboard.twitch.tv/extensions/bdcdj3ip5cpyzuskiicevvi6pqjwb3',
      // },
      // {
      //   label: 'Reese’s Trick or Treat',
      //   url: 'https://dashboard.twitch.tv/extensions/ujgtm298fp3f6unf0eioejpf04kmkn',
      // },
      // {
      //   label: '#StarTrekUnited',
      //   url: 'https://dashboard.twitch.tv/extensions/q1mxgp1shh5om7nmrly6exfyhlpohp',
      // },
      // {
      //   label: 'The Expanse Trivia',
      //   url: 'https://dashboard.twitch.tv/extensions/wab9jn2vpno4m48rn7sn39195d3fzq',
      // },
      // {
      //   label: 'Tug of War',
      //   url: 'https://dashboard.twitch.tv/extensions/sykmdeag9cvscn1y1p5pf0dbxn0fi0',
      // },
      // {
      //   label: 'Twitch Rivals',
      //   url: 'https://dashboard.twitch.tv/extensions/a5qgosum7si05cdwematgg2ozjtaq2',
      // },
      // {
      //   label: 'Twitter Timeline',
      //   url: 'https://dashboard.twitch.tv/extensions/qcxdzgqw0sd1u50wqtwodjfd5dmkxz',
      // },
      // {
      //   label: 'Upload Trivia',
      //   url: 'https://dashboard.twitch.tv/extensions/1uc1jk3sbp8f7vlsq8y2pcuptdfc2d',
      // },
      // {
      //   label: 'Vote Chess',
      //   url: 'https://dashboard.twitch.tv/extensions/jc33vupm4iiw2yelzgu5t54b5v6um7',
      // },
      // {
      //   label: 'Wingstop Flavor Picker',
      //   url: 'https://www.twitch.tv/ext/eadcyk4oe0pma86eju1walkgsqau7f',
      // },
    ],
  }
];
  
export {
  branding,
  etc,
  packaging,
  twitchExtensions,
  webDesign
};
