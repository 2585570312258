import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';

import { FaRegSmile } from 'react-icons/fa';
import { RiMenuFill, RiCloseFill } from 'react-icons/ri';

import { MdLightbulbOutline } from 'react-icons/md';

import { branding, etc, packaging, twitchExtensions, /* webDesign */ } from './projects';

import styles from './App.module.css';

const getClassNameFromArray = (array) => array?.filter(Boolean).join(' ');

const Home = lazy(() => import('./routes/home'));
// const Dev = lazy(() => import('./routes/dev'));
const Resume = lazy(() => import('./routes/resume'));
const Projects = lazy(() => import('./components/projects'));

const pages = [
  {
    slug: '',
    label: <>JW <FaRegSmile className={styles.icon} /></>,
    labelClassName: styles.initials,
    component: <Home />,
    render: (props) => <Home {...props} />,
  },
  {
    slug: 'twitch',
    label: 'Twitch Extensions',
  },
  /*
  {
    slug: 'dev',
    label: 'Web & Mobile Development',
    component: <Dev />,
    render: (props) => <Dev {...props} />,
  },
  */
  {
    slug: 'branding',
    label: 'Branding',
  },
  /*
  {
    slug: 'web-design',
    label: 'Web Design',
  },
  */
  {
    slug: 'etc',
    label: 'Etc',
  },
  {
    slug: 'resume',
    label: 'Resume / Contact',
    component: <Resume />,
    render: (props) => <Resume {...props} />,
  },
];

const Nav = (props) => {
  const {
    toggleDarkMode,
  } = props;

  const location = useLocation();
  const pathname = location?.pathname;
  const currentSlug = pathname && pathname.replace('/', '');

  const [showNavMenu, setShowNavMenu] = useState(false);

  const ref = useRef(null);

  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowNavMenu(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  });

  const renderNav = () => (
    <nav ref={ref}>
      {pages.map((page) => {
        const isActive = page.slug === currentSlug;
        return (
          <Link
            key={page.slug}
            to={'/' + page.slug}
            className={[page.labelClassName, isActive && styles.active].filter(Boolean).join(' ')}
            onClick={() => setShowNavMenu(false)}
          >
            {page.label}
          </Link>
        );
      })}
      <a href='/' onClick={(e) => { e.preventDefault(); toggleDarkMode() }}><MdLightbulbOutline /></a>
    </nav>
  );

  return (
    <>
      <div className={styles.nav}>{renderNav()}</div>
      <div className={getClassNameFromArray([styles.nav, styles.mobileNav])}>
        <div className={styles.menuButton} onClick={() => setShowNavMenu(!showNavMenu)}>{showNavMenu ? <RiCloseFill className={styles.icon} /> : <RiMenuFill className={styles.icon} />}</div>
        {showNavMenu && renderNav()}
      </div>
    </>
  );
};

const Footer = (props) => (
  <footer>
    © 2022 Jacob Winkelman <a href='/' onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); }}>Back to Top</a>
  </footer>
);

const Routes = (props) => {
  const location = useLocation();
  const pathname = location?.pathname
  let currentPageLabel = pages.find(page => '/' + page.slug === pathname)?.label;
  if (pathname && pathname === '/') {
    currentPageLabel = 'Portfolio';
  }
  const title = `Jacob Winkelman – ${currentPageLabel}`;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = title;
  },[pathname, title]);

  return (
    <Switch>
      <Route path='/branding'>
        <div>
          <Projects projects={branding} />
        </div>
      </Route>
      {/*
      <Route path='/dev'>
        <Dev />
      </Route>
      */}
      <Route path='/twitch'>
        <div>
          <Projects projects={twitchExtensions} />
        </div>
      </Route>
      <Route path='/etc'>
        <div>
          <Projects projects={packaging} />
          <Projects projects={etc} />
        </div>
      </Route>
      <Route path='/resume' component={Resume} />
      {/*
      <Route path='/web-design' >
        <div>
          <Projects projects={webDesign} />
        </div>
      </Route>
      */}
      <Route path='/' component={Home} />
    </Switch>  
  );
};

const App = () => {
  // const isBrowserDarkMode  = Boolean(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  // const [isDarkMode, setIsDarkMode] = useState(window.localStorage?.darkMode === 'true' || (!window.localStorage?.darkMode && isBrowserDarkMode));
  const [isDarkMode, setIsDarkMode] = useState(window.localStorage?.darkMode === 'true');

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    window.localStorage.setItem('darkMode', newDarkMode);
    setIsDarkMode(newDarkMode);
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <Router>
      <Suspense fallback={null}>
        <div className={styles.page}>
          <Nav toggleDarkMode={toggleDarkMode} />
          <Routes />
          <Footer />
        </div>
      </Suspense>
    </Router>
  );
};

export default App;
